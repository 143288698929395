import { render, staticRenderFns } from "./CardTake.vue?vue&type=template&id=57fa9ceb&scoped=true&"
import script from "./CardTake.vue?vue&type=script&lang=js&"
export * from "./CardTake.vue?vue&type=script&lang=js&"
import style0 from "./CardTake.vue?vue&type=style&index=0&id=57fa9ceb&prod&scoped=true&lang=css&"
import style1 from "./CardTake.vue?vue&type=style&index=1&id=57fa9ceb&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57fa9ceb",
  null
  
)

export default component.exports