<template>
  <div class="app center-div">
    <el-tabs v-model="kdzs.activeName" @tab-click="handleClick" stretch>
      <el-tab-pane name="kucun">
        <template slot="label">
          <span>
            <span v-show="kdzs.kc.loading" class="el-icon-loading"></span> 库存
          </span>
        </template>
        <div class="l5 text" v-show="total">
          品种数量 {{ total }} / {{ totalStock_nored }} 库存总数
          {{ totalStock }}
        </div>
        <div class="beihuodan_header">
          <div class="flex">
            <el-input
              clearable
              v-model="kdzs.kc.sysItemAlias"
              placeholder="货品简称"
              @keyup.enter.native="queryKucunData"
            ></el-input>
            <el-input
              clearable
              v-model="kdzs.kc.skuOuterId"
              placeholder="规格编码"
              @keyup.enter.native="queryKucunData"
            ></el-input>
            <div class="beihuodan_btn_div">
              <el-button
                icon="el-icon-copy-document"
                @click="onCopy()"
              ></el-button>
            </div>
            <div class="beihuodan_btn_div">
              <el-button
                icon="el-icon-delete"
                @click="clearKucunData"
              ></el-button>
            </div>
            <div class="beihuodan_btn_div">
              <el-button
                icon="el-icon-search"
                @click="queryKucunData"
              ></el-button>
            </div>
          </div>
        </div>
        <div class="kucun_results kucun_top scroll">
          <div class="kucun_item">
            <div class="kucun_color">
              <div>颜色</div>
            </div>
            <div class="kucun_img">
              <div>图片</div>
            </div>
            <div class="kucun_salableItemStock">
              <div>总数</div>
            </div>
            <div class="kucun_salableItemDistributableStock">
              <div>可售</div>
            </div>
            <div class="kucun_pan">
              <div>盘点</div>
            </div>
          </div>
          <div
            class="kucun_list kucun_item"
            v-for="stockInfo in currentPageData"
            :key="stockInfo.skuOuterId"
          >
            <!-- <div class="kucun_item" v-for="stockInfo in stockInfo.sysSkuStockInfoList" :key="stockInfo.skuOuterId"> -->
            <div class="kucun_color">
              <div>{{ stockInfo.skuOuterId }}</div>
            </div>
            <div class="kucun_img">
              <el-image
                fit="scale-down"
                style="width: 100%; height: 100%"
                :src="stockInfo.picUrl"
                :preview-src-list="[stockInfo.picUrl]"
                lazy
              ></el-image>
            </div>
            <div class="kucun_salableItemStock">
              <div>{{ stockInfo.salableItemStock }}</div>
            </div>
            <div class="kucun_salableItemDistributableStock">
              <div>{{ stockInfo.salableItemDistributableStock }}</div>
            </div>
            <div class="kucun_pan">
              <div class="kucun_pan kucun_pan_btn">
                <div>
                  <el-popover
                    ref="popover"
                    placement="top"
                    width="200"
                    v-model="stockInfo.visible"
                    @show="() => focusInput(stockInfo.skuOuterId)"
                  >
                    <div class="flex">
                      <el-input
                        :ref="'input' + stockInfo.skuOuterId"
                        v-model="stockInfo.stockCount"
                        placeholder="输入数量"
                        @keyup.enter.native="pandianStock(stockInfo)"
                      ></el-input>
                      <el-button size="mini" @click="pandianStock(stockInfo)"
                        >确定</el-button
                      >
                      <el-button
                        size="mini"
                        type="text"
                        @click="stockInfo.visible = false"
                        >取消</el-button
                      >
                    </div>
                    <el-button slot="reference" size="mini">盘点</el-button>
                  </el-popover>
                </div>
                <div>
                  <el-button size="mini" @click="clearStockInfo(stockInfo)"
                    >清空</el-button
                  >
                </div>

                <div>
                  <el-button size="mini" @click="showAddDialog(stockInfo)"
                    >添加</el-button
                  >
                </div>

                <div>
                  <el-button size="mini" @click="showReduceDialog(stockInfo)"
                    >减少</el-button
                  >
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <el-pagination
          hide-on-single-page
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      :title="`${title} 减少数量`"
      :visible.sync="dialogVisibleReduce"
      width="80%"
      @close="inputNumber = 0"
      @open="ReducefocusInput"
    >
      <el-input
        ref="Reduce_inputNumberRef"
        v-model.number="inputNumber"
        type="number"
        placeholder="请输入数量"
        @keyup.enter.native="handleConfirmReduce"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisibleReduce = false"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="handleConfirmReduce()"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="`${title} 添加数量`"
      :visible.sync="dialogVisibleAdd"
      width="80%"
      @close="inputNumber = 0"
      @open="AddfocusInput"
    >
      <el-input
        ref="Add_inputNumberRef"
        v-model.number="inputNumber"
        type="number"
        placeholder="请输入数量"
        @keyup.enter.native="handleConfirmAdd"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisibleAdd = false"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="handleConfirmAdd()"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      dialogVisibleAdd: false,
      dialogVisibleReduce: false,
      title: "",
      inputNumber: 0, // 存储用户输入的数字
      stockInfo: {
        skuOuterId: "",
        stockCount: "0",
      },
      // 分页数据
      currentPage: 1,
      pageSize: 20,
      total: 0,
      totalStock: 0, //库存总数
      totalStock_nored: 0, //库存大于0的列数
      // 快递组手
      kdzs: {
        activeName: "kucun",
        bhd: {
          loading: true,
          search: "",
          status: "WAIT_SELLER_SEND_GOODS", //待发货
          status_value: "待发货", //
          flagValue: "", // 留言备注
          flag_value: "留言备注", //
          startTime: "", //开始时间
          endTime: "", //结束时间
          shortNameIncluding: "", //款号
          skuIncluding: "", //规格
          di: false,
          list: {},
        },
        kc: {
          sysItemAlias: "",
          skuOuterId: "",
          loading: false,
          list: [],
        },
      },
      validStockInfos: [],
      remark: "",
    };
  },
  computed: {
    currentPageData() {
      let start = (this.currentPage - 1) * this.pageSize;
      let end = this.currentPage * this.pageSize;
      return this.kdzs.kc.list.slice(start, end);
    },
  },
  components: {},
  async activated() {},
  async mounted() {
    // 加载备货单开始结束日期
    this.jisuanriqi();
  },
  methods: {
    // 盘点
    pandianStock(stockInfo) {
      stockInfo.salableItemStock_j = stockInfo.salableItemStock;
      this.kucunCheckStock(stockInfo);
    },
    // 分页
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      // 可能还需要重新加载数据或者调整currentPage
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      // 根据新的currentPage重新加载或者筛选数据
    },
    // 复制
    onCopy() {
      this.kdzs.kc.loading = true;
      if (!this.kdzs.kc.list) {
        this.kdzs.kc.loading = false;
        this.$message.error("没有可复制的数据~");
        return;
      }

      // 1. 根据你的数据结构，这可能需要调整
      let data = this.kdzs.kc.list;

      data = data.filter((item) => item.salableItemDistributableStock > 0);

      if (!data || data.length === 0) {
        this.$message.error("没有可复制的数据~");
        this.kdzs.kc.loading = false;
        return;
      }
      const totalStock = data.reduce(
        (sum, item) => sum + Number(item.salableItemDistributableStock),
        0
      );
      this.totalStock = totalStock;

      // 2. 将数据数组转换为特定格式的字符串
      // 将总库存添加到格式化文本
      const formattedText =
        data
          .map(
            (item) => `${item.skuOuterId} ${item.salableItemDistributableStock}`
          )
          .join("\n") + `\n共 ${totalStock}`;

      // 3. 复制到剪贴板
      navigator.clipboard
        .writeText(formattedText)
        .then(() => {
          this.$message.success("复制成功");
        })
        .catch((err) => {
          console.error("复制失败", err);
          this.$message.error("复制失败");
        });
      this.kdzs.kc.loading = false;
    },
    // 显示添加对话框
    showAddDialog(stockInfo) {
      this.title = stockInfo.skuOuterId;
      this.stockInfo = stockInfo;
      this.inputNumber = 1;
      this.dialogVisibleAdd = true;
    },
    // 显示减少对话框
    showReduceDialog(stockInfo) {
      this.title = stockInfo.skuOuterId;
      this.stockInfo = stockInfo;
      this.inputNumber = 1;
      this.dialogVisibleReduce = true;
    },
    // 用户点击确定后的处理函数，这里以添加为例
    async handleConfirmAdd() {
      this.stockInfo.salableItemStock = Number(
        this.stockInfo.salableItemStock || 0
      );
      this.stockInfo.salableItemStock_j = this.stockInfo.salableItemStock;
      this.stockInfo.salableItemStock += this.inputNumber;
      this.stockInfo.salableItemStock = String(this.stockInfo.salableItemStock);
      this.stockInfo.stockCount = this.stockInfo.salableItemStock;
      await this.kucunCheckStock(this.stockInfo);
      this.dialogVisibleAdd = false;
    },
    // 用户点击确定后的处理函数，这里以减少为例
    async handleConfirmReduce() {
      let inputNumber = Number(this.inputNumber || 0);
      this.stockInfo.salableItemStock_j = this.stockInfo.salableItemStock;
      this.stockInfo.salableItemStock -= inputNumber;
      this.stockInfo.salableItemStock = String(this.stockInfo.salableItemStock);
      this.stockInfo.stockCount = this.stockInfo.salableItemStock;
      await this.kucunCheckStock(this.stockInfo);
      this.dialogVisibleReduce = false;
    },
    // 单个清空库存
    clearStockInfo(stockInfo) {
      this.$confirm(`确定要清空 ${stockInfo.skuOuterId} 的库存吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          stockInfo.stockCount = "0";
          stockInfo.salableItemStock_j = stockInfo.salableItemStock;
          this.kucunCheckStock(stockInfo);
        })
        .catch(() => {
          //关闭
        });
    },

    // 自动获取焦点
    focusInput(skuOuterId) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs["input" + skuOuterId][0].focus();
        }, 300); // 设置300毫秒延时，可以根据需要调整
      });
    },
    AddfocusInput() {
      this.$nextTick(() => {
        this.$refs.Add_inputNumberRef.focus();
        setTimeout(() => {
          this.$refs.Add_inputNumberRef.select();
        }, 0);
      });
    },
    ReducefocusInput() {
      this.$nextTick(() => {
        this.$refs.Reduce_inputNumberRef.focus();
        setTimeout(() => {
          this.$refs.Reduce_inputNumberRef.select();
        }, 0);
      });
    },
    // 加载日期
    jisuanriqi() {
      // 获取当前日期
      const today = new Date();

      // 获取三个月前的日期
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(today.getMonth() - 3);

      // 将日期转换为字符串格式为"yyyy-MM-dd HH:mm:ss"
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");

        if (date === threeMonthsAgo) {
          return `${year}-${month}-${day} 00:00:00`;
        } else if (date === today) {
          return `${year}-${month}-${day} 23:59:59`;
        } else {
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
      };

      this.kdzs.bhd.startTime = formatDate(threeMonthsAgo);
      this.kdzs.bhd.endTime = formatDate(today);
    },
    //切换
    handleClick(tab, event) {},
    // 修改待发货筛选
    up_bhd_status(command) {
      let status_value;
      switch (command) {
        case "WAIT_SELLER_SEND_GOODS":
          status_value = "待发货";
          break;
        case "WAIT_BUYER_CONFIRM_GOODS":
          status_value = "已发货";
          break;
        case "TRADE_FINISHED":
          status_value = "交易成功";
          break;
        case "WAIT_SELLER_SEND_GOODS,WAIT_BUYER_CONFIRM_GOODS,SELLER_CONSIGNED_PART":
          status_value = "待发货+已发货";
          break;
        default:
          status_value = "";
      }
      this.kdzs.bhd.status = command;
      this.kdzs.bhd.status_value = status_value;
    },
    // 修改留言备注
    up_bhd_status_flag(command) {
      this.kdzs.bhd.flagValue = command;
      // 更新显示的文本
      switch (command) {
        case "10":
          this.kdzs.bhd.flag_value = "有留言或备注";
          break;
        case "3":
          this.kdzs.bhd.flag_value = "有留言和备注";
          break;
        case "4":
          this.kdzs.bhd.flag_value = "无留言和备注";
          break;
        case "13":
          this.kdzs.bhd.flag_value = "无留言或无备注";
          break;
        case "1":
          this.kdzs.bhd.flag_value = "有留言";
          break;
        case "2":
          this.kdzs.bhd.flag_value = "有备注";
          break;
        case "6":
          this.kdzs.bhd.flag_value = "黄旗";
          break;
        default:
          this.kdzs.bhd.flag_value = "留言备注";
          break;
      }
    },
    // 备货单查询
    queryData() {
      this.kdzs.bhd.loading = true;
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/kdzsbei",
        data: {
          shortNameIncluding: this.kdzs.bhd.shortNameIncluding,
          skuIncluding: this.kdzs.bhd.skuIncluding,
          status: this.kdzs.bhd.status,
          startTime: this.kdzs.bhd.startTime,
          endTime: this.kdzs.bhd.endTime,
          flagValue: this.kdzs.bhd.flagValue,
        },
      })
        .then((res) => {
          if (res.data.supplierGroupItems.length == 0) {
            this.$message.error("没有内容");
            return false;
          }
          this.kdzs.bhd.di = true;
          this.kdzs.bhd.loading = false;
          this.kdzs.bhd.list = {
            supplierGroupItems: res.data.supplierGroupItems,
            totalCost: res.data.totalCost,
            totalItemCount: res.data.totalItemCount,
            tradeCountMap: res.data.tradeCountMap,
            tradeTotal: res.data.tradeTotal,
          };
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // kdzsbeisum
    queryDataSum() {
      this.kdzs.bhd.loading = true;
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/kdzsbeisum",

        data: {
          shortNameIncluding: this.kdzs.bhd.shortNameIncluding,
          skuIncluding: this.kdzs.bhd.skuIncluding,
          startTime: this.kdzs.bhd.startTime,
          endTime: this.kdzs.bhd.endTime,
        },
      })
        .then((res) => {
          if (res.data.supplierGroupItems.length == 0) {
            this.$message.error("没有内容");
            return false;
          }
          this.kdzs.bhd.di = true;
          this.kdzs.bhd.loading = false;
          if (res.data.supplierGroupItems.length == 0) {
            return false;
          }
          this.kdzs.bhd.list = {
            supplierGroupItems: res.data.supplierGroupItems,
            totalCost: res.data.totalCost,
            totalItemCount: res.data.totalItemCount,
            tradeCountMap: res.data.tradeCountMap,
            tradeTotal: res.data.tradeTotal,
          };
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 清空库存
    clearKucunData() {
      this.$confirm(`确定要清空所有库存吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const updatedStockInfos = this.kdzs.kc.list.map((stockInfo) => {
            return {
              ...stockInfo,
              stockCount: "0",
              salableItemStock_j: stockInfo.salableItemStock,
            };
          });
          this.kucunCheckStock(updatedStockInfos);
        })
        .catch(() => {
          //关闭
        });
    },
    // 库存查询
    queryKucunData() {
      this.currentPage = 1;
      if (this.kdzs.kc.sysItemAlias == "" && this.kdzs.kc.skuOuterId == "") {
        this.$message.error("货品简称与规格编码 不能同时为空");
        return;
      }
      this.kdzs.kc.loading = true;
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/kucunPageList", // 这里应该填入你的库存查询API
        data: {
          sysItemAlias: this.kdzs.kc.sysItemAlias,
          skuOuterId: this.kdzs.kc.skuOuterId,
        },
      })
        .then((res) => {
          this.kdzs.kc.loading = false;
          this.kdzs.kc.di = false;
          // this.kdzs.kc.list = {
          //   list: res.data.list,
          //   pageNo: res.data.pageNo,
          //   pageSize: res.data.pageSize,
          //   total: res.data.total,
          // };

          let kc_list = res.data.list.flatMap(
            (item) => item.sysSkuStockInfoList
          );
          this.kdzs.kc.list = kc_list;
          this.total = kc_list.length;
          this.totalStock = this.kdzs.kc.list
            .filter((item) => item.salableItemDistributableStock > 0)
            .reduce(
              (sum, item) => sum + Number(item.salableItemDistributableStock),
              0
            );

          this.totalStock_nored = kc_list.filter(
            (item) => item.salableItemDistributableStock > 0
          ).length;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 判断是否为数字
    validateStockInfo(stockInfo) {
      const regex = /^[0-9]+$/;
      if (!regex.test(stockInfo.stockCount)) {
        this.$message.error("请输入有效的整数");
        stockInfo.stockCount = "";
        return false;
      }
      return true;
    },
    async kucunCheckStock(stockInfo) {
      const isArray = Array.isArray(stockInfo);

      if (isArray) {
        this.validStockInfos = stockInfo.map((stockInfo) => {
          return {
            skuOuterId: stockInfo.skuOuterId,
            salableItemStock_j: stockInfo.salableItemStock_j,
            sysItemId: stockInfo.sysItemId,
            sysItemSkuId: stockInfo.sysItemSkuId,
            checkCount: stockInfo.stockCount, // 修改后的库存
          };
        });
      } else {
        if (this.validateStockInfo(stockInfo)) {
          this.validStockInfos.push({
            skuOuterId: stockInfo.skuOuterId,
            salableItemStock_j: stockInfo.salableItemStock_j,
            sysItemId: stockInfo.sysItemId,
            sysItemSkuId: stockInfo.sysItemSkuId,
            checkCount: stockInfo.stockCount, // 修改后的库存
          });
        }
      }

      // 去重逻辑: 过滤掉 checkCount 和 salableItemStock_j 相同的项
      this.validStockInfos = this.validStockInfos.filter(
        (stockInfo) => stockInfo.checkCount !== stockInfo.salableItemStock_j
      );

      // 生成 remark 字符串
      this.remark = this.validStockInfos
        .map(
          (stockInfo) =>
            `${stockInfo.skuOuterId} 原总数:${stockInfo.salableItemStock_j} 修改为:${stockInfo.checkCount}`
        )
        .join("\n");

      // 格式化数据
      this.validStockInfos = this.validStockInfos.map(
        ({ sysItemId, sysItemSkuId, checkCount }) => ({
          sysItemId,
          sysItemSkuId,
          checkCount,
        })
      );

      if (this.validStockInfos.length == 0) {
        this.$message({
          message: "无须清空",
          duration: 1000,
        });
        return;
      }
      try {
        const response = await this.$axios.post("/api/kanban/kucunCheckStock", {
          checkItemRelationDTOList: this.validStockInfos,
          remark: this.remark,
        });
        // 处理响应
        this.queryKucunData();
        this.validStockInfos = []; // 提交成功后清空列表
      } catch (error) {
        console.error(error);
      }
    },
    // // 库存盘点
    // kucunCheckStock2(stockInfo) {
    //   // console.log(stockInfo);
    //   // return;
    //   // 使用正则表达式验证 stockCount 是整数且不包含小数点
    //   const regex = /^[0-9]+$/;
    //   if (!regex.test(stockInfo.stockCount)) {
    //     // 输入不合法，可以在此处显示错误提示
    //     this.$message.error("请输入有效的整数");
    //     // 重置输入框的值，或者采取其他适当的措施
    //     stockInfo.stockCount = ""; // 清空输入框
    //     return;
    //   }

    //   let {
    //     sysItemId,
    //     stockCount,
    //     sysItemSkuId,
    //     skuOuterId,
    //     salableItemStock,
    //   } = stockInfo;
    //   this.$axios({
    //     method: "post", //请求方式
    //     url: "/api/kanban/kucunCheckStock", // 这里填入你的库存修改API

    //     data: {
    //       // salableItemStock,//总数
    //       // skuOuterId,//颜色
    //       sysItemId, //父级id
    //       sysItemSkuId, //自己id
    //       stockCount, //修改后的 库存数量
    //       checkCount: stockCount,
    //       remark: `${skuOuterId} 原总数:${salableItemStock} 修改为:${stockCount}`,
    //     },
    //   })
    //     .then((res) => {
    //       // 这里处理服务器的响应
    //       // 可能需要更新你的库存数据
    //       this.queryKucunData();
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
    // 删除指定字符
    filterText: function (text) {
      // 删除指定的词组
      let termsToRemove = [
        "颜色分类:",
        "制道手工皮具",
        "经典",
        "斜挎包",
        "手提包",
        "头层",
        "植鞣",
        "牛皮",
        "包",
        "女",
      ];
      for (let term of termsToRemove) {
        let regex = new RegExp(term, "g");
        text = text.replace(regex, "");
      }

      // 删除匹配正则表达式的部分
      text = text.replace(/\d+天内发货/g, "");

      return text;
    },
  },
  watch: {},
};
</script>

<style scoped>
.center-div {
  max-width: 750px;
  margin: 0 auto;
  display: block;
  /* background-color: #f2f2f2; */
  /* padding: 20px; */
  border-radius: 8px; /* 这将元素的角进行圆化 */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* 这会给元素添加一点阴影以产生深度效果 */
}

/* .app{background:#fff;} */

/* 备货单日期 */
:deep(.beihuodan_header_riqi input) {
  padding: 0 8px;
  font-size: 12px;
}
.beihuodan_header {
  border-bottom: 2px dashed #afafaf;
  padding-bottom: 8px;
  padding: 0 4px 8px;
}
.beihuodan_btn_div {
  max-width: 100px;
  display: flex;
  width: 100px;
}
:deep(.beihuodan_btn_div button) {
  padding: 3px;
  flex: 1;
}
.beihuodan_results {
  font-size: 12px;
  height: calc(100vh - 550px);
  padding-bottom: 100px;
}
.item-info {
  width: 60px;
  word-wrap: break-word;
  word-break: break-all;
  padding: 0 4px;
}
.item-totalCount {
  width: 40px;
  text-align: center;
}
.beihuodan_results .beihuo_item {
  display: flex;
  align-items: start;
  border-bottom: 1px dashed #5a5a5a;
}
.beihuo_list .beihuo_item {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.beihuodan_results .beihuo_item .beihuo_img {
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.beihuodan_results .beihuo_item .beihuo_img .item-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.beihuo_re_header {
  height: 30px;
}
.sku-name {
  width: 90px;
}
/* 库存 */
.kucun_results {
  font-size: 12px;
}
.kucun_results .kucun_item {
  display: flex;
  min-height: 53px;
  max-height: 60px;
}
.kucun_results_header {
  text-align: center;
}
.kucun_top .kucun_item {
  min-height: 30px;
}
.kucun_color {
  width: 80px;
}
.kucun_img {
  width: 50px;
}
.kucun_salableItemStock {
  width: 30px;
  text-align: center;
}
.kucun_salableItemDistributableStock {
  width: 30px;
  text-align: center;
}
.kucun_pan {
  flex: 1;
}
.kucun_pan_btn {
  display: flex;
  width: 96px;
  flex-wrap: wrap;
}
:deep(.kucun_pan button) {
  padding: 6px 10px;
  width: 48px;
  box-sizing: border-box;
}
.kucun_results {
  padding-bottom: 100px;
  height: calc(100vh - 450px);
}
</style>
